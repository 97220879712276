<template>
    <v-container class="w-container-users v-overflow editUtente"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

       

        <v-form style="max-width:700px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemAzienda"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="condizioni_pagamento.companyid" interface="action-sheet" disabled
                                readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <div id="cont_tipologia" style="cursor:pointer">
                        <ion-item id="itemTipologia"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Tipologia </ion-label>

                            <ion-select id="tipologia" name="tipologia" class='tipologia' cancel-Text="Chiudi"
                                done-Text="" :value="condizioni_pagamento.tipologia" interface="action-sheet">

                                <ion-select-option v-for="item in tipologie" :key="item.value" :value='item.value'> {{
                                item.description }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="showCompletoParziale">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="condizioni_pagamento.descrizione" id="descrizione" label="Descrizione">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row v-if="showCompleto">


                <v-col cols="12" md="12">

                    <div id="d_n_giorni"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Numero Giorni</label>

                                    <!-- <vue-autonumeric v-model="condizioni_pagamento.n_giorni" :disabled="true" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0'
                                    }"></vue-autonumeric> -->

                                    <vue-autonumeric v-model="condizioni_pagamento.n_giorni" :disabled="true" :options="'integer'"></vue-autonumeric>


                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="showCompleto">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="condizioni_pagamento.tipo" id="tipo" label="Tipo">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row v-if="showParziale">


                <v-col cols="12" md="12">

                    <div id="d_n_rate"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Numero Rate</label>

                                    <vue-autonumeric v-model="condizioni_pagamento.n_rate" :disabled="true" :options="'integer'"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="showParziale">

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="condizioni_pagamento.dilazione" id="dilazione" label="Dilazione">
                    </v-text-field>

                </v-col>


            </v-row>





        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="Clienti Logo" class="  " contain src="@/assets/aziende-invoice.png"
                                            max-width="24" max-height="24" transition="scale-transition" />
                                    
                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <div class="contpopupngiorni">

            <template>
                <modal name="popupNGiorni" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Numero Giorni</label>

                            <div id="d_n_giorni_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <!-- <vue-autonumeric v-model="numeroGiorni" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldNGiorni" id="fldNGiorni"></vue-autonumeric> -->

                                            <vue-autonumeric v-model="numeroGiorni" :options="'integer'"
                                                ref="fldNGiorni" id="fldNGiorni"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-n-giorni" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-n-giorni" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupnrate">

            <template>
                <modal name="popupNRate" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Numero Rate</label>

                            <div id="d_n_rate_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="numeroRate" :options="'integer'" ref="fldNRate" id="fldNRate"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-n-rate" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-n-rate" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apibanche from "../utils/banche/apibanche";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import apicondizionipagamento from "../utils/condizionipagamento/apicondizionipagamento";

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {

        this.$root.$children[0].checkLogout();


        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;

        pointerVue.$root.$children[0].normalDiv1 = true;
        pointerVue.$root.$children[0].normalDiv2 = true;

        pointerVue.$root.$children[0].invoiceDiv1 = false;
        pointerVue.$root.$children[0].invoiceDiv2 = false;
        pointerVue.$root.$children[0].invoiceDiv3 = false;
        pointerVue.$root.$children[0].invoiceDiv4 = false;
        pointerVue.$root.$children[0].invoiceDiv3Agila = false;

        pointerVue.$root.$children[0].calcolatorDiv1 = false;

        var namesurname = window.$cookies.get("namesurname");

        pointerVue.$root.$children[0].curTitle = namesurname;
        //pointerVue.$root.$children[0].invoiceTitle = "Condizioni Pagamento";


        //window.$cookies.get("aziende_user")

        this.setupButtons();

        if (this.$route.params.id == 0) {

            //this.$root.$children[0].curTitle = "Aggiungi Condizione Pagamento";
            pointerVue.$root.$children[0].invoiceTitle = "Aggiungi Condizione Pagamento";

            setTimeout(() => {

                this.syncAziende();

                this.syncMailGroup();


            }, 100);

        }

        if (this.$route.params.id > 0) {

            //this.$root.$children[0].curTitle = "Modifica Condizione Pagamento";
            pointerVue.$root.$children[0].invoiceTitle = "Modifica Condizione Pagamento";

            setTimeout(() => {



                this.syncBanca();


            }, 100);

        }

        if (this.$route.params.id > 0) {

           // this.$root.$children[0].curTitle = "Modifica Utente";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/condizioniPagamento"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {


            $('body').on('blur', '#dilazione', function (event) {
                console.log(event);



                pointerVue.condizioni_pagamento.dilazione = this.value;

                pointerVue.setupButtons();


            });




            $('body').on('blur', '#tipo', function (event) {
                console.log(event);



                pointerVue.condizioni_pagamento.tipo = this.value;

                pointerVue.setupButtons();


            });


            $('body').on('blur', '#descrizione', function (event) {
                console.log(event);



                pointerVue.condizioni_pagamento.descrizione = this.value;

                pointerVue.setupButtons();


            });


            $('body').on('ionChange', '.tipologia', function (event) {
                console.log(event);

                console.log("VALUE: ", this.value);


                if (this.value == "C") {
                    pointerVue.showParziale = false
                    pointerVue.showCompletoParziale = true;
                    pointerVue.showCompleto = true;
                }

                if (this.value == "P") {
                    pointerVue.showCompleto = false;
                    pointerVue.showCompletoParziale = true;
                    pointerVue.showParziale = true
                }

                pointerVue.condizioni_pagamento.tipologia = this.value;

                pointerVue.setupButtons();


            });


            $('body').on('click', '.btn-confirm-n-giorni', function (event) {
                console.log(event);

                console.log("NUM GIORNI: ", pointerVue.numeroGiorni);


                pointerVue.condizioni_pagamento.n_giorni = pointerVue.numeroGiorni;

                pointerVue.$root.$children[0].setFieldActive("#d_n_giorni");

                pointerVue.$modal.hide('popupNGiorni');

                pointerVue.setupButtons();

            });


            $('body').on('click', '.btn-cancel-n-giorni', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupNGiorni');

            });

            $('body').on('click', '#d_n_giorni', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupNGiorni');

            });

            $('body').on('click', '#d_n_rate', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupNRate');

            });



            $('body').on('click', '.btn-confirm-n-rate', function (event) {
                console.log(event);

                console.log("NUM GIORNI: ", pointerVue.numeroRate);


                pointerVue.condizioni_pagamento.n_rate = pointerVue.numeroRate;

                pointerVue.$root.$children[0].setFieldActive("#d_n_rate");

                pointerVue.$modal.hide('popupNRate');

                pointerVue.setupButtons();


            });


            $('body').on('click', '.btn-cancel-n-rate', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupNRate');

            });













            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });



            $('body').on('click', '#cont_idmailgroup', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });




            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });






            $('body').on('click', '.btn-confirm-fido-cassa', function (event) {
                console.log(event);

                pointerVue.banca.fido_cassa = pointerVue.fidoCassa;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_cassa");

                pointerVue.$modal.hide('popupFidoCassa');

            });


            $('body').on('click', '.btn-cancel-fido-cassa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoCassa');

            });

            $('body').on('click', '#d_fido_cassa', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoCassa');

            });





        });

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },



    },

    data: () => ({


        numeroGiorni: null,
        numeroRate: null,

        showCompletoParziale: false,
        showCompleto: false,
        showParziale: false,


        tipologie: [{ value: "C", description: "Completo" }, { value: "P", description: "Parziale" }],

        fidoAnt: null,
        fidoCassa: null,

        sheetGruppi: false,

        searchGruppi: "",

        condizioni_pagamento: { companyid: "", tipologia: "", descrizione: "", n_giorni: "", tipo: "", n_rate: "", dilazione: "" },

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {


        enableSaveButton: function () {

            var result = 1;

            console.log("CONDIZIONE_PAGAMENTO: ", this.condizioni_pagamento);

            if (this.condizioni_pagamento.companyid == "") {
                result = 0;
            }

            console.log(result);


            if (result == 1) {
                if (this.condizioni_pagamento.tipologia == "") {
                    result = 0;
                }
            }

            console.log(result);

            if (result == 1) {
                if (this.condizioni_pagamento.descrizione == "") {
                    result = 0;
                }
            }

            console.log(result);

            if (result == 1) {

                if (this.condizioni_pagamento.tipologia == "C") {

                    if (this.condizioni_pagamento.n_giorni == "") {
                        result = 0;
                    }

                }

            }

            console.log(result);

            if (result == 1) {

                if (this.condizioni_pagamento.tipologia == "C") {

                    if (this.condizioni_pagamento.tipo == "") {
                        result = 0;
                    }

                }

            }

            console.log(result);

            if (result == 1) {

                if (this.condizioni_pagamento.tipologia == "P") {

                    if (this.condizioni_pagamento.n_rate == "") {
                        result = 0;
                    }

                }

            }

            console.log(result);

            if (result == 1) {

                if (this.condizioni_pagamento.tipologia == "P") {

                    if (this.condizioni_pagamento.dilazione == "") {
                        result = 0;
                    }

                }

            }

            console.log(result);

            return result;

        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.condizioni_pagamento.companyid = azienda.companyid;

            this.setupButtons();

        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                var enableSave = this.enableSaveButton();

                if (enableSave == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-invoiceweb.png",
                        title: "Salva",
                        width: 30,
                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-invoiceweb.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35,
                });

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },


        validate() {

            var that = this;

            console.log("BANCA: ", that.banca);

            var errore = "";

            if ($("#companyid").val() == "") {
                errore = "Specificare l'azienda";
            }

            if (errore == "") {

                if ($("#nome").val() == "") {
                    errore = "Specificare il nome";
                }

            }


            if (errore == "") {

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },

        saveData: async function () {

            var that = this;

            console.log("COND PAG: ", that.condizioni_pagamento);

            var v_token = window.$cookies.get("token");

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apicondizionipagamento.goInsertCondPag(
                    that.condizioni_pagamento,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from goInsertCondPag", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/condizioniPagamento"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apicondizionipagamento.goUpdateCondPag(
                    that.condizioni_pagamento,
                    v_token,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from goUpdateCondPag", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/condizioniPagamento"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }








        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteNewApi(
                v_token,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncAziende", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncMailGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncMailGroup", res);

                this.gruppi = res.data.invoice_mail_group;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncBanca: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicondizionipagamento.getCondizioniPagamento(
                that.$route.params.id,
                v_token,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCondizioniPagamento", res);

                this.aziende = res.data.company;


                this.condizioni_pagamento = res.data.invoice_tipo_pagamento;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}





.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-users {
    width: 700px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editUtente .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editUtente .v-input {
    font-size: 1.2em !important;
}

.editUtente .v-select {
    font-size: 1.2em !important;
}

.editUtente .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
