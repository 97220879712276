import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIIva {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getGruppi(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("InvoiceWebBancheGetGruppi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async insertIva(iva, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
  
    for ( var key in iva ) {
      formData.append(key, iva[key]);
   }


    return axios.post(this.getUrlDomain("InvoiceWebIvaInsert"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async goInsertIva(iva, user)
  {

    let formData = new FormData();

    formData.append("username",  user);
  
    for ( var key in iva ) {
      formData.append(key, iva[key]);
   }


    return axios.post(this.getUrlDomain("goInsertIvaInvoice"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getIva(id, user, prodotto)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("prodotto",  prodotto);

    return axios.post(this.getUrlDomain("InvoiceWebEditIva"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateIva(iva, user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  
    for ( var key in iva ) {
      formData.append(key, iva[key]);
   }


    return axios.post(this.getUrlDomain("InvoiceWebIvaUpdate"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async goUpdateIva(iva, user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  
    for ( var key in iva ) {
      formData.append(key, iva[key]);
   }


    return axios.post(this.getUrlDomain("goUpdateIvaInvoice"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteMultiIva(user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  

    return axios.post(this.getUrlDomain("InvoiceWebIvaDelete"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async goDeleteMultiIva(user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
  

    return axios.post(this.getUrlDomain("goDeleteIvaInvoice"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

}

export default new APIIva({
  url: "http://emtool.local/public/api/smartbook/"
})
