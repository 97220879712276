import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIInvoice {


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;


  }


  async getClienti(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("InvoiceWebGetClientiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getFornitori(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("InvoiceWebGetFornitoriApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getClientiFattureFornitori(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("InvoiceWebGetClientiPerFornitoriApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  async getAziendaFiltro(user, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);

    return axios.post(this.getUrlDomain("InvoiceWebGetAziendaFiltroApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getInfoFattura(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_invoice", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebScadenziarioInfoFatturaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insPagamento(user, id_fattura, data_pagamento, importo_pagamento) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_invoice", id_fattura);
    formData.append("data_pagamento", data_pagamento);
    formData.append("importo_pagamento", importo_pagamento);

    return axios.post(this.getUrlDomain("InvoiceWebScadenziarioInsPagApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deletePagamenti(user, id, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_invoice", id_fattura);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("InvoiceWebScadenziarioDelPagApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteFattureClienti(user, id_fatture) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fatture);

    return axios.post(this.getUrlDomain("InvoiceWebDeleteFattureClientiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteFattureFornitori(user, id_fatture) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fatture);

    return axios.post(this.getUrlDomain("InvoiceWebDeleteFattureFornitoriApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async validatePdfFattura(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebValidatePdfFatturaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async createPdfFattura(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebCreatePdfFatturaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async validatePdfAnticipo(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebValidatePdfAnticipoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async createPdfAnticipo(user, id_fattura) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_fattura);

    return axios.post(this.getUrlDomain("InvoiceWebCreatePdfAnticipoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initParamsAutomatic(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("InvoiceWebAutoInitParamsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getDays(user, month) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("month", month);

    return axios.post(this.getUrlDomain("getDaysForMonth"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async validateAutomatic(user, month, day, company, year, budget) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_mese", month);
    formData.append("v_azienda", company);
    formData.append("v_giorno", day);
    formData.append("v_anno", year);
    formData.append("v_budget", budget);

    return axios.post(this.getUrlDomain("InvoiceWebAutoValidateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async createAutomatic(user, month, day, company, year, tipo) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_mese", month);
    formData.append("v_azienda", company);
    formData.append("v_giorno", day);
    formData.append("v_anno", year);
    formData.append("v_tipo_budget", tipo);

    return axios.post(this.getUrlDomain("InvoiceWebAutoCreateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async exportGridPdf(user, id_azienda, type_subject, filter_customer, filter_payment, val_date_scad_da, val_date_scad_a, val_date_emiss_da, val_date_emiss_a) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("companyid", id_azienda);
    formData.append("type_subject", type_subject);
    formData.append("curCustomer", filter_customer);
    formData.append("curPayment", filter_payment);
    formData.append("dateScadDa", val_date_scad_da);
    formData.append("dateScadA", val_date_scad_a);
    formData.append("dateEmissDa", val_date_emiss_da);
    formData.append("dateEmissA", val_date_emiss_a);

    return axios.post(this.getUrlDomain("InvoiceWebExportToPdfApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async exportGridPdfFornFatt(user, id_azienda, type_subject, filter_customer, filter_payment, val_date_scad_da, val_date_scad_a, val_date_emiss_da, val_date_emiss_a) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("companyid", id_azienda);
    formData.append("type_subject", type_subject);
    formData.append("curCustomer", filter_customer);
    formData.append("curPayment", filter_payment);
    formData.append("dateScadDa", val_date_scad_da);
    formData.append("dateScadA", val_date_scad_a);
    formData.append("dateEmissDa", val_date_emiss_da);
    formData.append("dateEmissA", val_date_emiss_a);

    return axios.post(this.getUrlDomain("InvoiceWebFattFornExportToPdfApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initInsertClienti(user, id_azienda, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company_id", id_azienda);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("InvoiceWebClientiInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getDettagliOrdine(data, id_ordine, user) {

    let formData = new FormData();

    formData.append("val_date", data);
    formData.append("val_id_ordine", id_ordine);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("InvoiceWebClientiDettOrdineApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getDettagliOrdineFornitore(data, id_ordine, user) {

    let formData = new FormData();

    formData.append("val_date", data);
    formData.append("val_id_ordine", id_ordine);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("InvoiceWebFornFattGetDetOrdApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async insertDetail(fattura_dettagli, user, id_azienda, id_ordine) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);
    formData.append("id_ordine", id_ordine);

    for (var key in fattura_dettagli) {
      formData.append(key, fattura_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebClientiInsDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDetail(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("InvoiceWebClientiGetDetailApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateDetail(fattura_dettagli, user, id_azienda, id_ordine, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_azienda", id_azienda);
    formData.append("id_ordine", id_ordine);
    formData.append("id", id);

    for (var key in fattura_dettagli) {
      formData.append(key, fattura_dettagli[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebClientiUpdDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteDetail(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("InvoiceWebClientiDelDetailsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveInvoice(fattura, user, operazione) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("operazione", operazione);

    for (var key in fattura) {
      formData.append(key, fattura[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebClientiSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEditClienti(user, id_invoice, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_invoice);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("InvoiceWebClientiInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async initInsertFornitori(user, id_azienda, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company_id", id_azienda);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("InvoiceWebFornitoriInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initInsertFornitoriNew(user, id_azienda, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company_id", id_azienda);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("InvoiceWebFornitoriInitInsertNewApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  async initInsertFornitoriFatture(user, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company_id", id_azienda);


    return axios.post(this.getUrlDomain("InvoiceWebFornitoriFattInitInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveInvoiceFornitori(fattura, user, operazione, file, v_radio, v_mot_rej) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("operazione", operazione);
    formData.append("file", file);
    formData.append("v_radio", v_radio);
    formData.append("v_mot_rej", v_mot_rej);

    for (var key in fattura) {
      formData.append(key, fattura[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebFornitoriSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveInvoiceFornitoriNew(fattura, user, operazione, file, v_radio, v_mot_rej) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("operazione", operazione);
    formData.append("file", file);
    formData.append("v_radio", v_radio);
    formData.append("v_mot_rej", v_mot_rej);

    for (var key in fattura) {
      formData.append(key, fattura[key]);
    }

    return axios.post(this.getUrlDomain("saveInvoiceFornitoriNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveInvoiceFornitoriFatture(fattura, user, operazione, file) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("operazione", operazione);
    formData.append("file", file);

    for (var key in fattura) {
      formData.append(key, fattura[key]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebFattFornitoriSaveApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async initEditFornitori(user, id_invoice, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_invoice);
    formData.append("companyid", id_azienda);


    return axios.post(this.getUrlDomain("InvoiceWebFornitoriInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initEditFattureFornitori(user, id_invoice, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_invoice);
    formData.append("companyid", id_azienda);


    return axios.post(this.getUrlDomain("InvoiceWebFattFornitoriInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async checkNumber(number, company, id, user) {

    let formData = new FormData();

    formData.append("val_number", number);
    formData.append("val_our_company", company);
    formData.append("id", id);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("InvoiceWebClientiCheckNumberApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updFattAuto(id, anno, mese, giorno) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("anno", anno);
    formData.append("mese", mese);
    formData.append("giorno", giorno);

    return axios.post(this.getUrlDomain("InvoiceWebUpdInvRealeApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async salvaAnticipo(id, impAntic) {

    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("impAntic", impAntic);

    return axios.post(this.getUrlDomain("InvoiceWebClientiSaveAdvanceApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteAnticipi(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("InvoiceWebClientiDeleteAdvanceApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async setContab(idazi, data_scadenza) {

    let formData = new FormData();

    formData.append("v_idazi", idazi);
    formData.append("v_data_scadenza", data_scadenza);

    return axios.post(this.getUrlDomain("InvoiceWebClientiDeleteAdvanceApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async salvaContab(v_id, v_importo, v_desc, v_tipo, v_id_azienda) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("v_importo", v_importo);
    formData.append("v_desc", v_desc);
    formData.append("v_tipo", v_tipo);
    formData.append("v_id_azienda", v_id_azienda);

    return axios.post(this.getUrlDomain("InvoiceWebClientiSaveContabApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getContab(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("InvoiceWebClientiGetContabApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteContab(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("InvoiceWebClientiDeleteContabApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getRemainingContab(v_id, v_id_azienda, v_def_inv, v_periodo) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("v_id_azienda", v_id_azienda);
    formData.append("v_def_inv", v_def_inv);
    formData.append("v_periodo", v_periodo);

    return axios.post(this.getUrlDomain("InvoiceWebClientiRemainingContabApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getInvoiceContab(v_type, v_companyid, v_date) {

    let formData = new FormData();

    formData.append("v_type", v_type);
    formData.append("v_companyid", v_companyid);
    formData.append("v_date", v_date);

    return axios.post(this.getUrlDomain("InvoiceWebClientiInvoiceContabApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getBancheContab(v_companyid) {

    let formData = new FormData();

    formData.append("v_companyid", v_companyid);

    return axios.post(this.getUrlDomain("InvoiceWebClientiInvoiceBancheContabApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async salvaSaldoBanche(val_saldo, v_companyid) {

    let formData = new FormData();

    formData.append("v_companyid", v_companyid);
    formData.append("val_saldo", val_saldo);

    return axios.post(this.getUrlDomain("InvoiceWebClientiBancheContabSaveSaldoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveSelInv(v_id_sel, v_companyid, v_tipo, v_data, v_def_inv, v_tipo_fatt) {

    let formData = new FormData();

    formData.append("v_companyid", v_companyid);
    formData.append("v_id_sel", v_id_sel);
    formData.append("v_tipo", v_tipo);
    formData.append("v_data", v_data);
    formData.append("v_def_inv", v_def_inv);
    formData.append("v_tipo_fatt", v_tipo_fatt);

    return axios.post(this.getUrlDomain("InvoiceWebClientiContabSaveSelInvApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getScadenza(v_user, v_id_sel) {

    let formData = new FormData();

    formData.append("v_user", v_user);
    formData.append("v_id_sel", v_id_sel);

    return axios.post(this.getUrlDomain("InvoiceWebGetScadenzaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveDataInvoiceExpire(v_data, v_days, v_id) {

    let formData = new FormData();

    formData.append("v_data", v_data);
    formData.append("v_days", v_days);
    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("saveDataInvoiceExpire"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteDataIvoiceExpire(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteDataIvoiceExpire"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async changeStatusBozza(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("changeStatusBozza"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async checkFileMultiExist(file_name) {

    let formData = new FormData();

    formData.append("file_name", file_name);

    return axios.post(this.getUrlDomain("InvoiceWebCheckFileeMultiExist"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async uploadDocumentsMulti(files) {

    let formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }

    return axios.post(this.getUrlDomain("InvoiceWebUploadDocumentsMulti"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getNumXmlFiles(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("InvoiceWebGeNumXmlFilesSuppliers"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async exportXmlAsZip(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("InvoiceWebExportXmlAsZip"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getOrdiniOld(id_azienda, nome, id_cliente, attivi, non_attivi) {

    let formData = new FormData();

    formData.append("id_azienda", id_azienda);
    formData.append("nome", nome);
    formData.append("id_cliente", id_cliente);
    formData.append("attivi", attivi); 
    formData.append("non_attivi", non_attivi);


    return axios.post(this.getUrlDomain("InvoiceGetOrdini"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getOrdini(id_azienda, nome, id_cliente, tipo) {

    let formData = new FormData();

    formData.append("id_azienda", id_azienda);
    formData.append("nome", nome);
    formData.append("id_cliente", id_cliente);
    formData.append("tipo", tipo); 


    return axios.post(this.getUrlDomain("InvoiceGetOrdini"), formData, { 'Content-Type': 'multipart/form-data' });

  }

}

export default new APIInvoice({
  url: "http://emtool.local/public/api/smartbook/"
})
